import i18n from 'i18next'
import { FC, useEffect, useMemo } from 'react'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import configuration from '~/configuration'
import useBoundStore from '~/lib/store'

/**
 * common resources for en
 */
import en_activity from '../../public/locales/en/activity.json'
import en_auth from '../../public/locales/en/auth.json'
import en_button from '../../public/locales/en/button.json'
import en_candidates from '../../public/locales/en/candidates.json'
import en_careers from '../../public/locales/en/careers.json'
import en_common from '../../public/locales/en/common.json'
import en_form from '../../public/locales/en/form.json'
import en_interview from '../../public/locales/en/interview.json'
import en_job from '../../public/locales/en/job.json'
import en_label from '../../public/locales/en/label.json'
import en_notification from '../../public/locales/en/notification.json'
import en_onboarding from '../../public/locales/en/onboarding.json'
import en_placements from '../../public/locales/en/placements.json'
import en_settings from '../../public/locales/en/settings.json'
import en_talent_pool from '../../public/locales/en/talent-pool.json'
import en_task from '../../public/locales/en/task.json'
import en_tooltip from '../../public/locales/en/tooltip.json'
import en_assign_jobs from '../../public/locales/en/assign-job.json'
import en_referrals from '../../public/locales/en/referrals.json'
/**
 * common resources for vn
 */
import vn_activity from '../../public/locales/vn/activity.json'
import vn_auth from '../../public/locales/vn/auth.json'
import vn_button from '../../public/locales/vn/button.json'
import vn_candidates from '../../public/locales/vn/candidates.json'
import vn_careers from '../../public/locales/vn/careers.json'
import vn_common from '../../public/locales/vn/common.json'
import vn_form from '../../public/locales/vn/form.json'
import vn_interview from '../../public/locales/vn/interview.json'
import vn_job from '../../public/locales/vn/job.json'
import vn_label from '../../public/locales/vn/label.json'
import vn_notification from '../../public/locales/vn/notification.json'
import vn_onboarding from '../../public/locales/vn/onboarding.json'
import vn_placements from '../../public/locales/vn/placements.json'
import vn_settings from '../../public/locales/vn/settings.json'
import vn_talent_pool from '../../public/locales/vn/talent-pool.json'
import vn_task from '../../public/locales/vn/task.json'
import vn_tooltip from '../../public/locales/vn/tooltip.json'
import vn_assign_jobs from '../../public/locales/vn/assign-job.json'
import vn_referrals from '../../public/locales/vn/referrals.json'

/**
 * common resources for ja
 */
import ja_activity from '../../public/locales/ja/activity.json'
import ja_auth from '../../public/locales/ja/auth.json'
import ja_button from '../../public/locales/ja/button.json'
import ja_candidates from '../../public/locales/ja/candidates.json'
import ja_careers from '../../public/locales/ja/careers.json'
import ja_common from '../../public/locales/ja/common.json'
import ja_form from '../../public/locales/ja/form.json'
import ja_interview from '../../public/locales/ja/interview.json'
import ja_job from '../../public/locales/ja/job.json'
import ja_label from '../../public/locales/ja/label.json'
import ja_notification from '../../public/locales/ja/notification.json'
import ja_onboarding from '../../public/locales/ja/onboarding.json'
import ja_placements from '../../public/locales/ja/placements.json'
import ja_settings from '../../public/locales/ja/settings.json'
import ja_talent_pool from '../../public/locales/ja/talent-pool.json'
import ja_task from '../../public/locales/ja/task.json'
import ja_tooltip from '../../public/locales/ja/tooltip.json'
import ja_assign_jobs from '../../public/locales/ja/assign-job.json'
import ja_referrals from '../../public/locales/ja/referrals.json'

const defaultEnResource = {
  common: en_common,
  auth: en_auth,
  form: en_form,
  button: en_button,
  tooltip: en_tooltip,
  label: en_label,
  notification: en_notification,
  task: en_task,
  activity: en_activity,
  onboarding: en_onboarding,
  candidates: en_candidates,
  careers: en_careers,
  job: en_job,
  interview: en_interview,
  settings: en_settings,
  talent_pool: en_talent_pool,
  placements: en_placements,
  assignJobs: en_assign_jobs,
  referrals: en_referrals
}
const defaultVnResource = {
  common: vn_common,
  auth: vn_auth,
  form: vn_form,
  button: vn_button,
  tooltip: vn_tooltip,
  label: vn_label,
  notification: vn_notification,
  task: vn_task,
  activity: vn_activity,
  onboarding: vn_onboarding,
  candidates: vn_candidates,
  interview: vn_interview,
  careers: vn_careers,
  job: vn_job,
  settings: vn_settings,
  talent_pool: vn_talent_pool,
  placements: vn_placements,
  assignJobs: vn_assign_jobs,
  referrals: vn_referrals
}
const defaultJaResource = {
  common: ja_common,
  auth: ja_auth,
  form: ja_form,
  button: ja_button,
  tooltip: ja_tooltip,
  label: ja_label,
  notification: ja_notification,
  task: ja_task,
  activity: ja_activity,
  onboarding: ja_onboarding,
  candidates: ja_candidates,
  interview: ja_interview,
  careers: ja_careers,
  job: ja_job,
  settings: ja_settings,
  talent_pool: ja_talent_pool,
  placements: ja_placements,
  assignJobs: ja_assign_jobs,
  referrals: ja_referrals
}

const withTranslateProvider = <F extends object>(
  Component: FC<F>,
  pageResources: {
    en: { [nameSpace: string]: object }
    vn: { [nameSpace: string]: object }
    ja: { [nameSpace: string]: object }
  } = { en: {}, vn: {}, ja: {} }
) => {
  const WithTranslateProvider = <T extends F>(props: T) => {
    const { user } = useBoundStore()

    useEffect(() => {}, [])
    const i18nInstance = useMemo(() => {
      i18n.use(initReactI18next).init({
        resources: {
          en: { ...defaultEnResource, ...pageResources.en },
          vn: { ...defaultVnResource, ...pageResources.vn },
          ja: { ...defaultJaResource, ...pageResources.ja }
        },
        lng: user.language,
        fallbackLng: configuration.site.locale,
        interpolation: {
          escapeValue: false
        }
      })
      return i18n
    }, [user?.language])

    return (
      <I18nextProvider i18n={i18nInstance}>
        <Component {...props} />
      </I18nextProvider>
    )
  }
  return WithTranslateProvider
}
export default withTranslateProvider
